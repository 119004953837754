import FormInterpretor, {
  FormInterpretorProps,
} from '@molecules/FormInterpretor/form-interpretor.component';
import { useCallback } from 'react';
import { contents, Data } from './login.contents';
import { useSetRecoilState } from 'recoil';
import LoginAtom from './login.atom';
import { useHistory } from 'react-router-dom';

const Login = () => {
  const setToken = useSetRecoilState(LoginAtom);
  const history = useHistory();
  const handleSubmit: FormInterpretorProps['handleSubmit'] = useCallback(
    async (data: Data) => {
      setToken(data.token);
      history.push('/');
      return {
        body: `Tudo certo!`,
        title: 'Sucesso!',
        severity: 'success',
      };
    },
    [setToken, history],
  );
  return (
    <FormInterpretor
      handleSubmit={handleSubmit}
      contents={contents}
      header="Inserir Código de Acesso"
      subheader="Insira um código de acesso valido abaixo"
    />
  );
};

export default Login;
